import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    user_info: {},
    onlineBasePath: "http://fafs.antiplagiarize.com/",
    flow: [],
    sidebarActive: 0,
    // Loading 实例
    exampleLoading: "",
  },
  getters: {},
  mutations: {
    setUserInfo(state, data) {
      state.user_info = data;
    },
    ChangeSideBarId(state, payload) {
      state.sidebarActive = payload;
    },
    setExampleLoading(state, data) {
      state.exampleLoading = data;
    },
    setFlow(state, data) {
      state.flow = data;
    },
  },
  actions: {},
  modules: {},
});
