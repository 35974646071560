import Vue from 'vue'
import VueRouter from 'vue-router'
import versionTood from '../utils/versionUpdate'

const originPush = VueRouter.prototype.push
const originReplace = VueRouter.prototype.replace

VueRouter.prototype.push = function (location, onComplete, onAbort) {
  if (!onComplete && !onAbort) {
    originPush.call(this, location, () => {})
  } else {
    originPush.call(this, location, onComplete, onAbort)
  }
}
VueRouter.prototype.replace = function (location, onComplete, onAbort) {
  if (!onComplete && !onAbort) {
    originReplace.call(this, location, () => {})
  } else {
    originReplace.call(this, location, onComplete, onAbort)
  }
}
Vue.use(VueRouter)

const routes = [{
    path: '/',
    component: () => import('@/views/login/login'),
  },
  {
    path: '/index',
    name: 'Index',
    redirect: '/index/home',
    component: () => import('@/views/index/index'),
    children: [{
        path: 'home',
        name: 'Home',
        component: () => import('@/views/index/indexMainRight/home/home'),
      },
      //产品管理
      {
        path: 'product',
        name: 'Product',
        component: () => import('@/views/index/indexMainRight/product/product'),
      },
      {
        path: 'order',
        name: 'Order',
        component: () => import('@/views/index/indexMainRight/order/order'),
      },
      // 公司管理
      {
        path: 'company',
        name: 'company',
        component: () => import('@/views/index/indexMainRight/company/company'),
      },
      // 分账列表
      {
        path: 'accounts',
        name: 'accounts',
        component: () =>
          import('@/views/index/indexMainRight/accounts/accounts'),
      },
      //产品详情
      {
        path: 'productInfo',
        name: 'ProductInfo',
        component: () =>
          import('@/views/index/indexMainRight/product/productInfo'),
      },
      //产品内容
      {
        path: 'productContent',
        name: 'ProductContent',
        component: () =>
          import('@/views/index/indexMainRight/product/productContent'),
      },
      //提现记录
      {
        path: 'application',
        name: 'application',
        component: () =>
          import('@/views/index/indexMainRight/application/application'),
      },
      // 系统维护
      {
        path: 'maintenance',
        name: 'maintenance',
        component: () =>
          import('@/views/index/indexMainRight/maintenance/maintenance'),
      },
      //查看
      {
        path: 'viewpresetword',
        name: 'viewpresetword',
        component: () =>
          import(
            '@/views/index/indexMainRight/paint/viewpresetword'
          ),
      },
      //新增页面
      {
        path: 'savepresetword',
        name: 'savepresetword',
        component: () =>
          import(
            '@/views/index/indexMainRight/paint/savepresetword'
          ),
      },
      //图片发散页面
      {
        path: 'picture',
        name: 'picture',
        component: () => import('@/views/index/indexMainRight/paint/picture'),
      },
      //预置词管理
      {
        path: 'presetword',
        name: 'presetword',
        component: () => import('@/views/index/indexMainRight/paint/presetword'),
      },
      //主题管理
      {
        path: 'subjectList',
        name: 'subjectList',
        component: () => import('@/views/index/indexMainRight/paint/subjectList'),
      },
      //主题与维度
      {
        path: 'mapList',
        name: 'mapList',
        component: () => import('@/views/index/indexMainRight/paint/mapList'),
      },
      //维度词管理
      {
        path: 'dimensionList',
        name: 'dimensionList',
        component: () => import('@/views/index/indexMainRight/paint/dimensionList'),
      },
      //模型主题管理
      {
        path: 'modelSubjectList',
        name: 'modelSubjectList',
        component: () => import('@/views/index/indexMainRight/paint/modelSubjectList'),
      },
      //画师任务设置
      {
        path: 'taskList',
        name: 'taskList',
        component: () => import('@/views/index/indexMainRight/paint/task/taskList')
      },
      //画师任务管理
      {
        path: 'taskInfoList',
        name: 'taskInfoList',
        component: () => import('@/views/index/indexMainRight/paint/task/taskInfoList')
      },
      //客服工单
      {
        path: 'jobOrderManagement',
        name: 'jobOrderManagement',
        component: () => import('@/views/index/indexMainRight/paint/jobOrderManagement')
      },
      //绘画管理
      {
        path: 'paintingList',
        name: 'paintingList',
        component: () => import('@/views/index/indexMainRight/paint/paintingList')
      },
      //作品管理
      {
        path: 'wordList',
        name: 'wordList',
        component: () => import('@/views/index/indexMainRight/paint/wordList')
      },
      //委托画师管理
      {
        path: 'entrust',
        name: 'entrust',
        component: () => import('@/views/index/indexMainRight/paint/painterEntrustList')
      },
      //配音管理
      {
        path: 'workMusicList',
        name: 'workMusicList',
        component: () => import('@/views/index/indexMainRight/paint/workMusicList')
      },
      //执业资格审批
      {
        path: 'qualification',
        name: 'qualification',
        component: () => import('@/views/index/indexMainRight/library/qualification')
      },
      //执业类型
      {
        path: 'practiceType',
        name: 'PracticeType',
        component: () => import('@/views/index/indexMainRight/library/practiceType')
      },
      //上报执业类型管理
      {
        path: 'practiceReport',
        name: 'practiceReport',
        component: () => import('@/views/index/indexMainRight/paint/practiceReport')
      },
      //需求任务管理
      {
        path: 'requirementTask',
        name: 'requirementTask',
        component: () => import('@/views/index/indexMainRight/paint/requirementTask')
      },
      //查看
      {
        path: 'requirementInfo',
        name: 'requirementInfo',
        component: () =>
          import(
            '@/views/index/indexMainRight/paint/requirementInfo'
          ),
      },
    ],
  },
]

const router = new VueRouter({
  mode: 'history',
  routes,
})

// // /* 添加路由守卫 */
router.beforeEach((to, from, next) => {
  versionTood.isNewVersion()
  next()
})

export default router