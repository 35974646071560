import Vue from "vue";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "@/assets/scss/reset.scss";
import uploader from "vue-simple-uploader";
Vue.use(uploader);
import { createLoading } from "./utils/utils";

// import SuperFlow from "vue-super-flow";
// import "vue-super-flow/lib/index.css";
//自定义loading 方法
Vue.prototype.$createLoading = createLoading;
let EventBus = new Vue();
Object.defineProperties(Vue.prototype, {
  $bus: {
    get() {
      return EventBus;
    },
  },
});

Vue.config.productionTip = false;
Vue.use(ElementUI);
// Vue.use(SuperFlow);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
