import { render, staticRenderFns } from "./App.vue?vue&type=template&id=701b2556&scoped=true&"
var script = {}
import style0 from "./App.vue?vue&type=style&index=0&id=701b2556&prod&lang=scss&scoped=true&"
import style1 from "./App.vue?vue&type=style&index=1&id=701b2556&prod&lang=css&"


/* normalize component */
import normalizer from "!../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "701b2556",
  null
  
)

export default component.exports