import { Loading } from "element-ui";

import store from "../store/index";
export function debounce(fn, delay = 200) {
  let timer;
  return function () {
    let arg = arguments;
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(() => {
      fn.apply(this, arg);
    }, delay);
  };
}

export function chunk(array, count) {
  let result = [];
  //遍历输出成员
  array.forEach((item, index) => {
    let temp = Math.floor(index / count);
    //检验数组是否初始化
    if (!(result[temp] instanceof Array)) {
      result[temp] = new Array();
    }
    result[temp].push(item);
  });
  return result;
}

export function isEmptyObj(obj) {
  return obj && Object.keys(obj).length == 0;
}

export function createLoading(dom) {
  const option = {
    target: dom,
    lock: true,
    spinner: "custom-icon",
    background: "rgba(51, 51, 51, 0)",
    customClass: "custom-bg",
  };
  const loading = Loading.service(option);
  store.commit("setExampleLoading", loading);
  return loading;
}
